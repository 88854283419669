<template>
  <div>
    <v-card class="card pa-6 mb-6">
      <v-row>
        <v-col cols="12">
          <h3 class="mb-2">
            <v-chip class="primary black--text mr-4" label>1</v-chip>Branch Name
          </h3>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="slab_style"
            :items="items"
            :rules="[(v) => !!v || 'Item is required']"
            label="Default Slab"
            required
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="default_percentage"
            :rules="percentRules"
            suffix="%"
            type="number"
            label="Percent"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-row-reverse">
        <v-btn text @click="addSlab" class="primary--text">
          <v-icon>mdi-plus</v-icon>
          <span>Add New SLAB</span>
        </v-btn>
      </v-row>
      <div v-for="(n,i) in slabC" :key="i" class="mb-6">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12">
              <span>
               SLAB {{n}}
                <v-btn @click="deleteSlab(n-1)" v-if="n>2" icon>
                  <v-icon class="primary--text">mdi-minus-circle-outline</v-icon>
                </v-btn>
              </span>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="sb.start[n-1]"
                :rules="valueRules"
                type="number"
                label="Starting Value"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="sb.end[n-1]"
                :rules="valueRules"
                type="number"
                label="Ending Value"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="sb.dine_in[n-1]"
                :rules="percentRules"
                suffix="%"
                type="number"
                label="Dine-In Percentage"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="sb.takeway_provider[n-1]"
                :rules="percentRules"
                suffix="%"
                type="number"
                label="Provider Percentage"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="!step4edit" outlined text color="primary" @click="$emit('back3')">Go Back</v-btn>
      <v-btn :loading="loading" :disabled="sStatus=='' || (sStatus=='Default Percentage' && pStatus=='') || ((sStatus=='Normal' || sStatus=='Last Slab') && !valid)" @click="onSlab" class="primary float-right">
        {{step4edit ? 'Save Changes' : 'Finish'}}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { eventBus } from '../../main';
import store from '../../store'
export default {
  props: ["step2Status", "step4edit", "editBranchData"],
  data() {
    return {
      valid: false,
      loading: false,
      items: ["Default Percentage", "Normal", "Last Slab"],
      slab_style: "",
      default_percentage: null,
      sStatus: '',
      pStatus: '',
      sb: {
        start: [],
        end: [],
        dine_in: [],
        takeway_provider: [],
      },
      percentRules: [(v) => !!v || "percentage is required"],
      valueRules: [(v) => !!v || "Value is required"],

      slabC: 2,
      slab: {},
      branch_id: '',
      tenant_id: '',
    };
  },

  watch: {
    slab_style(val) {
      this.sStatus = val
      // console.log(this.sStatus, 'watching' , this)
    },
    default_percentage(val) {
      this.pStatus = val
      // console.log(this.pStatus)
    }
  },

  mounted() {
    if(this.step4edit) {
      // console.log('edit data in slab form', this.editBranchData)
      // console.log('slab length',Object.keys(this.editBranchData.slab).length)
      this.slabC = Object.keys(this.editBranchData.slab).length
      this.slab_style = this.editBranchData.slab_style
      this.default_percentage = this.editBranchData.default_percentage
      let i = 0
      for (const property in this.editBranchData.slab) {
        // console.log(this.editBranchData.slab[property])
        this.sb.start[i] = this.editBranchData.slab[property].start
        this.sb.end[i] = this.editBranchData.slab[property].end
        this.sb.dine_in[i] = this.editBranchData.slab[property].dine_in
        this.sb.takeway_provider[i] = this.editBranchData.slab[property].takeway_provider
        i++
      }
    } else {
      // console.log('step2Status in slb form', this.step2Status)
      // console.log(store.getters.TenantId, 'branch id', store.getters.BranchId)
      this.tenant_id = store.getters.TenantId
      this.branch_id = store.getters.BranchId
    }
  },

  methods: {
    addSlab() {
      this.slabC++
    },
    deleteSlab(p) {
      // console.log('index of slabs array', p)
      // console.log('on delete slab', this.sb)
      for (const property in this.sb) {
        // console.log(`${this.sb[property]}`)
        this.sb[property].splice(p,1)
      }
      this.slabC --
    },
    onSlab() {
      this.loading = true

      // console.log('before validate', this.sb)
      // console.log('slab count',this.slabC)
      // console.log(this.slab_04)
      for (const property in this.sb) {
        // console.log(`${property}: ${this.sb[property]}`)
        this.sb[property].forEach((el,i) => {
          // console.log('checking', el, 'prop', property, 'index', i)
          if(property=="start") {
            this.slab['slab_0'+(i+1)] = {
              start: el
            }
          } else if (property=="end") {
            this.slab['slab_0'+(i+1)]['end'] = el
          } else if (property=="dine_in") {
            this.slab['slab_0'+(i+1)]['dine_in'] = el
          } else {
            this.slab['slab_0'+(i+1)]['takeway_provider'] = el
          }
        })
      }
      // console.log(window, this)

      // this can be done easily by JSON.stringify() and vice versa JSON.parse()
      // console.log('all slab',this.slab)
      let payload = {}
      if(this.step4edit) {
        payload["branch_id"] = this.editBranchData.branch_id
        payload["tenant_id"] = this.editBranchData.tenant_id
      } else {
        payload["branch_id"] = this.branch_id
        payload["tenant_id"] = this.tenant_id
      }
      payload["slab"] = this.slab
      payload["slab_style"] = this.slab_style
      if(this.pStatus=='') {
        payload["default_percentage"] = '0'
      } else {
        payload["default_percentage"] = this.default_percentage
      }
      // console.log('payload', payload)


      // for branch edit
      if(this.step4edit) {
        let slabPrev = {}
        let i = 1
        for (const property in this.editBranchData.slab) {
          // console.log('slab prev ',property)
          slabPrev['slab_0'+i] = {
            "start": this.editBranchData.slab[property].start,
            "end": this.editBranchData.slab[property].end,
            "dine_in": this.editBranchData.slab[property].dine_in,
            "takeway_provider": this.editBranchData.slab[property].takeway_provider
          }
          i++
        }
        let date = new Date()
        let dateArr = [date.getFullYear(), date.getMonth(), date.getDate()]
        let timeArr = [date.getHours(), date.getMinutes(), date.getSeconds()]
        payload["updated_dates"] = {
          "update_01": {
            "update_date": dateArr.join('-') + ' ' + timeArr.join(':'),
            "slab": slabPrev,
            "slab_style": this.editBranchData.slab_style,
            "default_percentage": this.editBranchData.default_percentage
          }
        }
        // console.log('edited payload', payload)
        // console.log('need to edit branch')
        store.dispatch('editBranch', payload)
        .then(res => {
          // console.log('after edit branch res', res)
          this.loading = false
          eventBus.$emit('snackbar', {
            step2: true,
            snackbarColor: "#31A274",
            snackbarText: `${res.data.message}`,
          })
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          eventBus.$emit('snackbar', {
            snackbarColor: "#FF5252",
            snackbarText: "Something Went Wrong!",
          })
        })
      }

      // branch attach
      else {
        // console.log('why coming here for adding',this.step4edit, this.step2Status)
        store.dispatch('attachBranch', payload)
        .then(res => {
          // console.log('after attach branch res', res.status)
          this.loading = false
          if (res.status === 200 || res.status === 201) {
            if(this.step2Status) {
              eventBus.$emit('snackbar', {
                step2: true,
                snackbarColor: "#31A274",
                snackbarText: `${res.data.message}`,
              })
            } else {
              eventBus.$emit('snackbar', {
                go: true,
                snackbarColor: "#31A274",
                snackbarText: `${res.data.message}`,
              })
            }
          } else {
            eventBus.$emit('snackbar', {
              snackbarColor: "#FF5252",
              snackbarText: `${res.data.message}`,
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          eventBus.$emit('snackbar', {
            snackbarColor: "#FF5252",
            snackbarText: "Something Went Wrong!",
          })
        })
      }

      // attach branch call

    }
  },
};
</script>
